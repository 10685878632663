.Toaster {
  height: 100%;
  width: 100%;

  > .toasts {
    align-items: center;
    display: flex;
    flex-direction: column-reverse;
    left: 0;
    margin: 0 auto;
    position: absolute;
    top: 110px;
    width: 100%;

    > div {
      background-color: var(--text-colour);
      border-radius: 5px;
      color: var(--text-colour-inverse);
      font-weight: bold;
      margin: 10px 0;
      min-width: 150px;
      padding: 15px 10px;
      text-align: center;
      transition: opacity 0.3s;
      z-index: 1;

      &.hidden {
        opacity: 0;
      }

      &.transient {
        z-index: 3;
      }
    }
  }
}
