.Keyboard {
  max-width: 500px;
  width: 100%;

  > div {
    display: flex;
    margin: 0 0px 8px;

    > button {
      background: var(--keyboard-colour);
      border: 0;
      border-radius: 4px;
      color: var(--text-colour);
      cursor: pointer;
      flex: 1;
      font-size: 13.3px;
      font-weight: 700;
      height: 58px;
      padding: 0;
      text-transform: uppercase;
      user-select: none;

      &:not(:last-of-type) {
        margin-right: 6px;
      }

      &.absent {
        background: var(--absent-colour);
        color: var(--text-colour-light);
      }

      &.correct {
        background: var(--correct-colour);
        color: var(--text-colour-light);
      }

      &.present {
        background: var(--present-colour);
        color: var(--text-colour-light);
      }

      &.wide {
        flex: 1.5;
        font-size: 12px;
      }

      &.doubleWide {
        flex: 2;
      }
    }

    > div {
      flex: 0.5;
    }
  }
}
