.App {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 auto;
  width: 100%;

  > .header {
    align-items: center;
    border-bottom: 1px solid var(--secondary-colour);
    display: flex;
    height: 51px;
    justify-content: space-between;
    padding: 3px 15px;
    width: calc(100% - 30px);

    > .title {
      color: var(--text-colour);
      font-family: 'Karnak Condensed Normal';
      font-size: 36px;
      font-weight: 700;
      letter-spacing: 0.01em;
      text-align: center;
      text-decoration: none;

      @media only screen and (max-width: 360px) {
        font-size: 22px;
        letter-spacing: 0.1rem;
      }
    }

    > .boof {
      display: flex;
      justify-content: center;
      left: 0;
      margin: auto;
      position: absolute;
      right: 0;
      top: 28px;

      > img {
        margin-left: 80px;
        width: 50px;

        @media only screen and (max-width: 360px) {
          margin-left: 45px;
          width: 30px;
        }
      }
    }

    > button {
      background: transparent;
      border: 0;
      cursor: pointer;
      display: flex;
      padding: 0;
    }
  }

  > .page {
    height: 100%;
    max-width: 700px;
    width: 100%;
  }
}
