@keyframes bounce {
  0%,
  20% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  50% {
    transform: translateY(5px);
  }

  60% {
    transform: translateY(-15px);
  }

  80% {
    transform: translateY(2px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes flip-in {
  0% {
    transform: rotateX(0);
  }

  100% {
    transform: rotateX(-90deg);
  }
}

@keyframes flip-out {
  0% {
    transform: rotateX(-90deg);
  }

  100% {
    transform: rotateX(0);
  }
}

.Tile {
  align-items: center;
  background: transparent;
  border: 2px solid transparent;
  box-sizing: content-box;
  color: var(--text-colour);
  display: flex;
  font-size: inherit;
  font-weight: 700;
  height: 100%;
  justify-content: center;
  padding: 0;
  text-transform: uppercase;
  transition: transform 0.05s;
  width: 100%;

  &.absent {
    background: var(--absent-colour);
    color: var(--tile-text-colour-light);
  }

  &.bounce {
    animation-duration: 1000ms;
    animation-name: bounce;
  }

  &.clickable {
    cursor: pointer;
  }

  &.correct {
    background: var(--correct-colour);
    color: var(--tile-text-colour-light);
  }

  &.empty {
    border: 2px solid var(--secondary-colour);
  }

  &.flipIn {
    animation-name: flip-in;
    animation-duration: 250ms;
    animation-timing-function: ease-in;
  }

  &.flipOut {
    animation-name: flip-out;
    animation-duration: 250ms;
    animation-timing-function: ease-in;
  }

  &.pending {
    border: 2px solid var(--accent-colour);
  }

  &.present {
    background: var(--present-colour);
    color: var(--tile-text-colour-light);
  }

  &.selected {
    border: 2px solid var(--correct-colour);
  }

  &.zoom {
    transform: scale(1.15);
  }
}
