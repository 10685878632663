.CreatePage {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;

  > .input {
    flex: 1;
    padding-top: 120px;
    text-align: center;

    @media only screen and (max-height: 650px) {
      padding-top: 50px;
    }

    > .tiles {
      margin: 0 auto;
      padding: 20px 0;
    }

    > .numLetters {
      padding: 5px 0;

      > select {
        margin-left: 5px;
      }
    }

    > label {
      align-items: center;
      display: flex;
      justify-content: center;

      > input {
        margin-left: 10px;
      }
    }

    > .or {
      font-size: 20px;
      font-weight: bold;
      margin: 25px 0;

      @media only screen and (max-height: 650px) {
        margin: 15px 0;
      }
    }

    > button {
      background: var(--correct-colour);
      border: 0;
      border-radius: 4px;
      color: var(--text-colour-light);
      cursor: pointer;
      font-weight: bold;
      margin: 0 5px;
      padding: 15px;


      @media only screen and (max-height: 650px) {
        font-size: 10px;
        padding: 10px;
      }
    }
  }
}
