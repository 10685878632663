.PlayPage {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;

  > .tiles {
    align-items: center;
    display: flex;
    flex: 1;
    min-height: 0;

    > .innerWrapper {
      box-sizing: content-box;
      display: flex;
      flex-direction: column;
      gap: 9px;
      justify-content: space-between;
    }
  }
}

.PlayPage-status {
  padding-top: 50px;
  text-align: center;
}

.PlayPage-modal {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  justify-content: center;
  text-align: center;

  > button {
    align-items: center;
    background: var(--correct-colour);
    border: 0;
    border-radius: 4px;
    color: var(--text-colour-light);
    cursor: pointer;
    display: flex;
    font-size: 20px;
    font-weight: bold;
    justify-content: center;
    max-width: 100%;
    padding: 15px 0;
    width: 240px;

    > svg {
      margin-left: 10px;
    }
  }

  > div > a {
    color: inherit;
  }
}
