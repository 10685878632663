@font-face {
  font-family: 'Karnak Condensed Normal';
  font-style: normal;
  font-weight: normal;
  src: url('assets/fonts/karnakcondensed-normal-700.woff2') format('woff2');
}

:root {
  --absent-colour: var(--secondary-colour);
  --accent-colour: #565758;
  --bg-colour: #121213;
  --correct-colour: #538d4e;
  --keyboard-colour: #818384;
  --present-colour: #b59f3b;
  --secondary-colour: #3a3a3c;
  --sidenav-website-hover-colour: #2f2f31;
  --switch-off-colour: #565758;
  --switch-on-colour: #6aaa64;
  --text-colour: var(--text-colour-light);
  --text-colour-dark: #000;
  --text-colour-inverse: var(--text-colour-dark);
  --text-colour-light: #fff;
  --tile-text-colour-light: var(--text-colour-light);
}

html {
  height: 100%;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background: var(--bg-colour);
  color: var(--text-colour);
  font-family: 'Helvetica Neue', Arial, sans-serif;
  height: 100%;
  margin: 0;
  overflow-y: hidden;

  &.lightMode {
    --absent-colour: #787c7e;
    --accent-colour: #878a8c;
    --bg-colour: #fff;
    --keyboard-colour: var(--secondary-colour);
    --secondary-colour: #d3d6da;
    --sidenav-website-hover-colour: #f4f4f4;
    --switch-off-colour: #878a8c;
    --text-colour: var(--text-colour-dark);
    --text-colour-inverse: var(--text-colour-light);
    --text-colour-light: #fff;
    --text-colour: var(--text-colour-dark);
  }

  &.colourBlindMode {
    --correct-colour: #f5793a;
    --present-colour: #85c0f9;
    --switch-on-colour: var(--correct-colour);
    --tile-text-colour-light: #fff;
  }

  &.deanMode {
    --bg-colour: url('assets/images/dean.jpg');
  }

  &.conesMode {
    --bg-colour: #31a35d;
  }
}

button {
  font-family: 'Helvetica Neue', Arial, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}
