.Switch {
  background: var(--switch-off-colour);
  border: 0;
  border-radius: 999px;
  display: flex;
  height: 20px;
  padding: 0;
  position: relative;
  width: 32px;

  &.on {
    background: var(--switch-on-colour);

    > div {
      left: 12px;
    }
  }

  > div {
    background: #fff;
    border-radius: 50%;
    height: 16px;
    left: 0;
    margin: 2px;
    position: absolute;
    transition: left 0.2s ease-in-out;
    width: 16px;
  }
}
