@keyframes shake {
  10%,
  90% {
    transform: translateX(-1px);
  }

  20%,
  80% {
    transform: translateX(2px);
  }

  30%,
  50%,
  70% {
    transform: translateX(-4px);
  }

  40%,
  60% {
    transform: translateX(4px);
  }
}

.TileRow {
  display: flex;
  gap: 5px;
  height: 100%;
  justify-content: space-between;
  width: 100%;

  &.shake {
    animation-duration: 600ms;
    animation-name: shake;
  }
}
